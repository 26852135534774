<template>
    <div class="content">
        <el-table :data="orderList" style="width: 100%" v-loading="isloading" stripe :row-style="isRed"
            @selection-change="selected">
            <el-table-column type="selection" width="55" />
            <el-table-column prop="report_date" label="报表日期" min-width="120" show-overflow-tooltip />
            <el-table-column prop="agent_name" label="代理商" min-width="180" show-overflow-tooltip />
            <el-table-column prop="merchant_name" label="商户名称" min-width="180" show-overflow-tooltip />
            <el-table-column prop="merchant_no" label="商户号" min-width="120" />
            <el-table-column prop="store_name" label="门店名称" min-width="180" show-overflow-tooltip />
            <el-table-column prop="store_id" label="门店ID" min-width="120" show-overflow-tooltip :align="'center'" />
            <el-table-column prop="device_id" label="设备ID" min-width="120" show-overflow-tooltip :align="'center'" />
            <el-table-column label="终端名称" min-width="120" show-overflow-tooltip :align="'center'">
                <template #default="{ row }">
                    {{ row?.terminal?.name }}
                </template>
            </el-table-column>
            <el-table-column prop="payment_method" label="支付方式" min-width="120" show-overflow-tooltip
                :align="'center'" />
            <el-table-column prop="total_orders" label="订单总数" min-width="120" show-overflow-tooltip :align="'center'" />
            <el-table-column prop="success_orders" label="交易成功订单数" min-width="180" show-overflow-tooltip
                :align="'center'" />
            <el-table-column prop="success_amount" label="交易成功金额" min-width="180" show-overflow-tooltip
                :align="'center'" />
            <el-table-column prop="refund_orders" label="退款订单数" min-width="120" show-overflow-tooltip
                :align="'center'" />
            <el-table-column prop="refund_amount" label="退款金额" min-width="120" show-overflow-tooltip
                :align="'center'" />
            <el-table-column prop="fee_amount" label="手续费" min-width="120" show-overflow-tooltip :align="'center'" />
            <el-table-column prop="settlement_amount" label="结算金额" min-width="120" show-overflow-tooltip
                :align="'center'" />
            <el-table-column label="操作" :align="'center'" fixed="right">
                <template #default="scope">
                    <el-link :underline="false" @click="handleMore(scope.row)" type="primary">详情</el-link>
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="暂无数据" :image-size="200" />
            </template>
        </el-table>
    </div>
</template>

<script setup>
import { ref, defineProps, watch, defineEmits } from 'vue';

// 接收父组件传递的 orderList
const props = defineProps(['orderList', 'isloading']);
const emit = defineEmits(['show-detail']);

const orderList = ref()

// 监听 orderList 的变化
watch(
    () => props.orderList,
    (newVal) => {
        orderList.value = newVal; // 直接使用父组件传递的数据
    },
    { immediate: true }
);

const selectedArrData = ref([]);

// 复选框被选中的事件
const selected = (row) => {
    selectedArrData.value = row;
};

// 设置选中行的样式
const isRed = ({ row }) => {
    if (selectedArrData.value.includes(row)) {
        return {
            backgroundColor: "#cde8f8"
        };
    }
    return {}; // 如果没有匹配到，返回空对象
};

// 处理详情按钮点击事件
const handleMore = (orderData) => {
    emit('show-detail', orderData); // 触发 show-detail 事件，并传递当前行的订单数据
};
</script>

<style lang="less" scoped>
.content {
    .icon-box {
        display: flex;
        flex-direction: row;

        .text-overflow {
            width: 90%;
            overflow: hidden;
            white-space: nowrap !important;
            text-overflow: ellipsis !important;
        }

        .copy {
            color: #409EFF !important;
            cursor: pointer;
        }

        .copyed {
            color: #52c41a !important;
        }

        .icon {
            font-size: 14px !important;

        }
    }
}


/* 当鼠标悬停在表格的行上时改变背景色 */
.el-table {
    --el-table-row-hover-bg-color: #cde8f8;
}

/* src/assets/styles/global.css */
/* 滚动条整体部分 */
::v-deep .el-scrollbar__bar {
    opacity: 1;
    /* 使滚动条不透明 */
}

/* 横向滚动条的滑块 */
::v-deep .el-scrollbar__bar.is-horizontal .el-scrollbar__thumb {
    border-radius: 1px;
    /* 滑块的圆角 */
    background-color: #818181;
    /* 滑块的背景颜色 */
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
    /* 滑块的阴影 */
}

/* 纵向滚动条的滑块 */
::v-deep .el-scrollbar__bar.is-vertical .el-scrollbar__thumb {
    border-radius: 1px;
    background-color: #818181;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
}

/* 滚动条的轨道部分 */
::v-deep .el-scrollbar__wrap {
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        /* 轨道的背景颜色 */
    }
}

/* 修改滑块宽度 */
::v-deep .el-scrollbar__bar.is-horizontal {
    height: 12px;
}

::v-deep .el-scrollbar__bar.is-vertical {
    width: 12px;
}
</style>