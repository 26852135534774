<template>
    <div class="history">
        <!-- 搜索组件 -->
        <page-search :search-config="searchConfig(searchOptions)" @query-click="handleQueryClick"
            @reset-click="handleResetClick"></page-search>
        <pageBoard :summary-data="summaryData"></pageBoard>

        <el-card shadow="never" style="margin-top: 10px;">
            <!-- 内容组件，传递 orderList 数据 -->
            <page-content :order-list="orderList" :isloading="isloading" @show-detail="handleShowDetail"></page-content>
            <!-- 分页组件 -->
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :total="total" :page-size="pageSize"
                    :current-page="currentPage" @current-change="handlePageChange" @size-change="handleSizeChange" />
            </div>
        </el-card>

        <!-- 订单详情弹出框 -->
        <page-modal ref="orderModal" />
    </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import pageSearch from '@/components/pageSearch/pageSearch.vue';
import pageContent from "./c-cpns/page-content.vue";
import pageBoard from "./c-cpns/page-board.vue";
import pageModal from "./c-cpns/page-modal.vue";
import searchConfig from './config/search.config';
import { ElMessage } from 'element-plus';

const { proxy } = getCurrentInstance();


// 订单列表数据
const orderList = ref([]);

const isloading = ref(false); // 定义加载状态

// 分页相关状态
const total = ref(0); // 总条数
const pageSize = ref(15); // 每页显示的条数
const currentPage = ref(1); // 当前页码

const searchForm = ref({});

// 处理查询事件
const handleQueryClick = (formData) => {
    currentPage.value = 1
    //查询历史统计列表
    todayList(formData)
    //查询看板数据
    todaySummary(formData)
};

//今日统计列表
const todayList = async (formData) => {
    searchForm.value = formData;
    isloading.value = true;
    try {
        const response = await proxy.$api.statistic.todayList({
            ...formData,
            page: currentPage.value,
            pageSize: pageSize.value
        });
        orderList.value = response.data.data; // 更新 orderList
        total.value = response.data.pagination.total; // 更新总条数

    } catch (error) {
        ElMessage.error('订单列表获取失败,请稍后重试');
    } finally {
        isloading.value = false; // 结束加载
    }
}

const summaryData = ref(null); // 用于存储看板数据
//今日统计列表-看板数据
const todaySummary = async (formData) => {
    try {
        const response = await proxy.$api.statistic.todaySummary({
            ...formData,
        });
        summaryData.value = response.data.data; // 更新看板数据

    } catch (error) {
        ElMessage.error('订单列表获取失败,请稍后重试');
    }
}

// 处理重置事件
const handleResetClick = (formData) => {
    total.value = 0; // 重置总条数
    currentPage.value = 1; // 重置当前页码
    handleQueryClick(formData);
};

// 处理页码变化
const handlePageChange = (page) => {
    currentPage.value = page;
    todayList(searchForm.value); // 重新查询数据
};

// 处理每页条数变化
const handleSizeChange = (size) => {
    pageSize.value = size;
    currentPage.value = 1; // 重置当前页码
    todayList(searchForm.value); // 重新查询数据
};


// 支付渠道
const channelList = ref([]);
const getpaymentChannels = async () => {
    try {
        const response = await proxy.$api.order.statistics.paymentChannels();
        channelList.value = response.data.map(item => ({
            value: item.id, // 将 id 映射为 value
            label: item.name // 将 name 映射为 label
        }));
    } catch (error) {
        ElMessage.error('支付渠道获取失败,请稍后重试');
    }
};

// 支付方式
const payTypeList = [
    { value: 1, label: "支付宝" },
    { value: 2, label: "微信" },
    { value: 3, label: "云闪付" }
];

// 合并后的配置对象
const searchOptions = ref({
    channelList: [],
    payTypeList,
});


// 处理显示订单详情
const orderModal = ref(null); // 获取 page-modal 组件的引用
const handleShowDetail = (orderData) => {
    orderModal.value.openModal(orderData); // 调用 page-modal 的 openModal 方法
};


// 组件挂载时执行默认查询
onMounted(() => {
    getpaymentChannels().then(() => {
        searchOptions.value.channelList = channelList.value;

        // 默认查询参数
        const defaultSearchForm = {};
        for (const item of searchConfig(searchOptions.value).formItems) {
            defaultSearchForm[item.prop] = item.initialValue ?? '';
        }

        // 触发默认查询
        handleQueryClick(defaultSearchForm);
    });
});
</script>

<style lang="less" scoped>
.history {
    .export-excel {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #18bc37;
        cursor: pointer;
        -webkit-user-select: none;
    }

    .export-excel:hover {
        color: #409eff;
    }

    .pagination {
        margin: 1.25rem 0;
        display: flex;
        justify-content: flex-end;
    }
}
</style>
