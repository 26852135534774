<template>
    <div class="page-modal">
        <!-- 弹出框 -->
        <el-dialog v-model="showModal" center>
            <el-descriptions :column="2" border label-width="200" class="uniform-width-descriptions">
                <el-descriptions-item label="报表日期">{{ currentOrder.report_date }}</el-descriptions-item>
                <el-descriptions-item label="代理商">{{ currentOrder.agent_name }}</el-descriptions-item>
                <el-descriptions-item label="商户名称">{{ currentOrder.merchant_name }}</el-descriptions-item>
                <el-descriptions-item label="商户号">{{ currentOrder.merchant_no }}</el-descriptions-item>
                <el-descriptions-item label="门店名称">{{ currentOrder.store_name }}</el-descriptions-item>
                <el-descriptions-item label="门店ID">{{ currentOrder.store_id }}</el-descriptions-item>
                <el-descriptions-item label="设备ID">{{ currentOrder.device_id }}</el-descriptions-item>
                <el-descriptions-item label="终端名称">{{ currentOrder?.terminal?.name }}</el-descriptions-item>
                <el-descriptions-item label="支付方式">{{ currentOrder.payment_method }}</el-descriptions-item>
                <el-descriptions-item label="订单总数">{{ currentOrder.total_orders }}</el-descriptions-item>
                <el-descriptions-item label="交易成功订单数">{{ currentOrder.success_orders }}</el-descriptions-item>
                <el-descriptions-item label="交易成功金额">{{ currentOrder.success_amount }}</el-descriptions-item>
                <el-descriptions-item label="退款订单数">{{ currentOrder.refund_orders }}</el-descriptions-item>
                <el-descriptions-item label="退款金额">{{ currentOrder.refund_amount }}</el-descriptions-item>
                <el-descriptions-item label="手续费">{{ currentOrder.fee_amount }}</el-descriptions-item>
                <el-descriptions-item label="结算金额">{{ currentOrder.settlement_amount }}</el-descriptions-item>
            </el-descriptions>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="showModal = false">关闭</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, defineExpose } from 'vue';

const showModal = ref(false);

// 当前选中的订单数据
const currentOrder = ref(null);

// 打开弹出框并设置当前订单数据
const openModal = (order) => {
    showModal.value = true;
    currentOrder.value = order
};




// 暴露 openModal 方法，供父组件调用
defineExpose({ openModal });
</script>

<style lang="less" scoped>
.page-modal {
    text-align: center;
    padding: 20px;

    .statuscheck {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
}

.dialog-footer {
    text-align: center;
}

:deep(.el-descriptions__content.el-descriptions__cell.is-bordered-content) {
    width: 30%;
    /* 统一设置内容区域宽度 */
}
</style>